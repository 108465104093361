import { Container } from "typescript-ioc";
import { TokenService } from "./TokenService";

declare global {
    interface IOri {
        /**
         * TokenService singleton instance.
         */
        tokenService?: TokenService;
    }
}

const tokenService = Container.get(TokenService);
if (window.ori) {
    // publish for access from legacy JS code
    ori.tokenService = tokenService;
    ori.modules.push("tokenService");
}
